import { Component, OnInit, Input } from '@angular/core';
import { OHNElement } from '../../../models/ohn-instances';

@Component({
  selector: 'app-audio-element-config',
  templateUrl: './audio-element-config.component.html',
  styleUrls: ['./audio-element-config.component.scss'],
})
export class AudioElementConfigComponent implements OnInit {

  @Input() el: OHNElement;

  constructor() { }

  ngOnInit() {}

}
