import { Component, OnInit, Input } from '@angular/core';
import { OHNElement } from '../../../models/ohn-instances';

@Component({
  selector: 'app-video-embed-element-config',
  templateUrl: './video-embed-element-config.component.html',
  styleUrls: ['./video-embed-element-config.component.scss'],
})
export class VideoEmbedElementConfigComponent implements OnInit {

  @Input() el: OHNElement;

  constructor() { }

  ngOnInit() {}

}
