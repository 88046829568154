import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { OHNElement } from '../../models/ohn-instances';
import { OhnService } from '../../services/ohn.service';
import { ENABLE_LINK_BOOKMARKS } from '../../../environments/environment';

@Component({
  selector: 'app-basic-elements-config-palette',
  templateUrl: './basic-elements-config-palette.component.html',
  styleUrls: ['./basic-elements-config-palette.component.scss'],
})
export class BasicElementsConfigPaletteComponent implements OnInit {

	@Output() newDescriptionElement = new EventEmitter();

	ENABLE_LINK_BOOKMARKS = ENABLE_LINK_BOOKMARKS;

	elementsPreset : any = {
		textElement : <OHNElement>{
			text : "",
			_cls : "ContentElement",
			controller : "contentElementController",
			settings : {},
			config : {},
			elements : []
		},
		imageElement : <OHNElement>{
			text : "",
			_cls : "ImageElement",
			controller : "imageElementController",
			settings : {},
			config: {
				class : "ion-text-start"
			},
			elements : [],
			image_url : ""
		},
		videoElement : <OHNElement>{
			text : "",
			_cls : "VideoElement",
			controller : "videoElementController",
			settings : {},
			config : {
				autoplay : false,
				controls : true,
				loop : false,
				muted : false,
			},
			elements : [],
			video_url : ""
		},
		videoEmbedElement : <OHNElement>{
			text : "",
			_cls : "VideoElement",
			controller : "videoEmbedElementController",
			settings : {},
			config : {},
			elements : [],
			video_url : ""
		},
		audioElement : <OHNElement>{
			text : "",
			_cls : "VideoElement",
			controller : "audioElementController",
			settings : {},
			config : {
				autoplay : false,
				controls : true,
				loop : false,
				muted : false
			},
			elements : [],
			video_url : ""
		},
		stringElement : <OHNElement>{
			text : "",
			_cls : "StringFieldElement",
			controller : "stringFieldController",
			settings : {},
			config : {},
			elements : []
		},
		numberElement : <OHNElement>{
			text : "",
			_cls : "NumericFieldElement",
			controller : "numericFieldController",
			settings : {},
			config : {
  			elementPrescriptions : {}
  		},
			elements : []
		},
		dateElement : <OHNElement>{
			text : "",
			_cls : "DateTimeFieldElement",
			controller : "dateTimeFieldController",
			settings : {},
			config : {},
			elements : []
		},
		pickOneElement : <OHNElement>{
			text : "",
			_cls : "PickOneFieldElement",
			controller : "pickOneDefaultController",
			settings : {},
			config : {},
			elements : []
		},
		yesNoElement : <OHNElement>{
			text : "",
			_cls : "PickOneFieldElement",
			controller : "yesNoController",
			settings : {},
			config : {},
			elements : [
				<OHNElement>{
					text : "Yes",
					_cls : "ContentElement",
					controller : "contentElementController",
					settings : {},
					config : {}
				},
				<OHNElement>{
					text : "No",
					_cls : "ContentElement",
					controller : "contentElementController",
					settings : {},
					config : {}
				}
			]
		},
		timerElement : <OHNElement>{
			text : "",
			_cls : "NumericFieldElement",
			controller : "timerElementController",
			settings : {},
			config : {
				time : 0,
				hours : 0,
				minutes : 0,
				seconds : 0
			},
			elements : []
		},
		speedSensorElement : <OHNElement>{
			text : "",
			_cls : "TimeSeriesFieldElement",
			controller : "speedSensorElementController",
			settings : {},
			config : {
  			elementPrescriptions : {}
  		},
			elements : []
		},
		fitbitHeartRateElement : <OHNElement>{
			text : "",
			_cls : "TimeSeriesFieldElement",
			controller : "fitbitHeartRateElementController",
			settings : {},
			config : {
  			elementPrescriptions : {}
  		},
			elements : []
		},
		noiseLevelElement : <OHNElement>{
			text : "",
			_cls : "TimeSeriesFieldElement",
			controller : "noiseLevelElementController",
			settings : {},
			config : {
  			elementPrescriptions : {}
  		},
			elements : []
		},
		notesElement : <OHNElement>{
			text : "",
			_cls : "StringFieldElement",
			controller : "notesController",
			settings : {},
			config : {
				title : ""
			},
			elements : []
		},
		linkElement : <OHNElement>{
			text : "",
			_cls : "ImageElement",
			controller : "linkElementController",
			settings : {},
			config : {},
			elements : [],
			image_url : ""
		},
		hkWeightElement : <OHNElement>{
			text : "",
			_cls : "NumericFieldElement",
			controller : "numericFieldController",
			settings : {},
			config : {},
			external_slug : {
				epic:"<placeholder>",
				google_fit:"weight",
				apple_healthkit:"weight"
			},
			elements : []
		},
		hkBloodPressureElement : <OHNElement>{
			text : "",
			_cls : "DictFieldElement",
			controller : "bloodPressureElementController",
			settings : {},
			config : {},
			external_slug : {
				epic:"<placeholder>",
				google_fit:"blood_pressure",
				apple_healthkit:"blood_pressure"
			},
			elements : []
		},
		hkHeartRateElement : <OHNElement>{
			text : "",
			_cls : "NumericFieldElement",
			controller : "heartRateElementController",
			settings : {},
			config : {},
			external_slug : {
				epic:"<placeholder>",
				google_fit:"heart_rate",
				apple_healthkit:"heart_rate"
			},
			elements : []
		},
		pickManyElement : <OHNElement>{
			text : "",
			_cls : "PickManyFieldElement",
			controller : "pickManyDefaultController",
			settings : {},
			config : {},
			elements : []
		},
		numericSliderElement : <OHNElement>{
			text : "",
			_cls : "NumericFieldElement",
			controller : "numericSliderFieldController",
			settings : {},
			config : {
				min : 0,
				max : 10,
				minText : "Min",
				maxText : "Max",
				step : 1
			}
		},
		externalLinkElement : <OHNElement>{
			text : "",
			_cls : "ImageElement",
			controller : "linkElementController",
			settings : {},
			config : {
				isButton : true
			},
			elements : [],
			image_url : ""
		},
	}

  constructor(
  	private ohnService: OhnService,
  ) { }

  ngOnInit() {}

  returnElement(type: string) {
  	this.newDescriptionElement.emit(this.ohnService.getObjectCopy(this.elementsPreset[type]));
  }

}
