import { Component, OnInit } from '@angular/core';
import { OhnLanguageService } from '../../services/ohn-language.service';

@Component({
  selector: 'app-language-selection',
  templateUrl: './language-selection.component.html',
  styleUrls: ['./language-selection.component.scss'],
})
export class LanguageSelectionComponent implements OnInit {

  languages : any[] = [];

  currentLanguage : any;

  constructor(
    private lS: OhnLanguageService,
  ) { }

  ngOnInit() {
    this.getLanguages();
  }
 
  getLanguages() {
    const locale = localStorage.getItem('ohn-locale') ? localStorage.getItem('ohn-locale') : 'en';
    this.languages = this.lS.getAvailableLocales();
    this.currentLanguage = this.languages.find(l=>{return l.locale == locale});
  }

  languageChanged() {
    this.lS.setCurrentLocale(this.currentLanguage.locale);
  }

}