import { Router, NavigationExtras } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import { OhnApiService } from './services/ohn-api.service';
import { OhnHealthApiService } from './services/ohn-health-api.service';
import { OhnService } from './services/ohn.service';
import { OhnLanguageService } from './services/ohn-language.service';
import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';
//import { WonderPush } from '@ionic-native/wonderpush';
 
import { Platform } from '@ionic/angular';

import { OHNElement, OHNUser } from './models/ohn-instances';
import { BranchedContentRendererComponent } from './components/branched-content-renderer/branched-content-renderer.component';
import { DisclaimerComponent } from './components/disclaimer/disclaimer.component';
import { APP_AVAILABLE_FOR_ROLES, NOT_AUTHORIZED_MESSAGE, DEFAULT_FOR_ROLES, WEB_APP_TITLE } from '../environments/environment';

import * as _ from 'underscore/underscore';

export interface Application extends OHNElement {
  menu_elements: [OHNElement],
  home_page: string
}
 
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html' 
})

export class AppComponent {

  app : Application;
  me : OHNUser;

  constructor(
    private platform: Platform,
    private authenticationService: AuthenticationService,
    private ohnApi: OhnApiService,
    private ohnService: OhnService,
    private router: Router,
    private titleService: Title,
    private ohnHealthApiService: OhnHealthApiService,
    public modalController: ModalController,
    public lS: OhnLanguageService,
  ) {
    this.ohnApi.tokenReceivedFromStorage.subscribe(received => {
      if (received) this.getMe();
    });
    this.initializeApp();
    this.lS.init();
  }
 
  initializeApp() {
    this.authenticationService.authenticationState.subscribe(state => {
        if (!state) {
          this.router.navigate(['login'], { replaceUrl: true });
        } else {
          this.router.navigate(['app-start'], { replaceUrl: true });
          //this.ohnService.startLoading('Loading ...');
        }
    });
    this.platform.ready().then(() => {
      this.titleService.setTitle(WEB_APP_TITLE);
      /*WonderPush.subscribeToNotifications()
       .then(() => console.log("User subscribed  to notifications"))
       .catch((error: any) => console.error(error));*/
      })
  }

  getMe(){
    this.ohnApi.getMe().subscribe(me => {
      this.loadAppByRole(me);
      //WonderPush.addTag(me.username);
    });
  }

  loadAppByRole(me: OHNUser) {
    this.me = me;
    this.saveTimezone();
    let loadDefaultApp : boolean = false;
    let appAvailable : boolean = false;
    let firstRoleMatch : string;
    _.each(APP_AVAILABLE_FOR_ROLES, (r)=>{
      if (_.contains(me.roles, this.ohnService.appRoleModifier(r, true))) {
        appAvailable = true;
        if (!firstRoleMatch) firstRoleMatch = r;
      }
      if (_.contains(me.roles, this.ohnService.appRoleModifier(r, true)) && !loadDefaultApp && _.contains(DEFAULT_FOR_ROLES, this.ohnService.appRoleModifier(r, false))) {
        loadDefaultApp = true;
      }
    });
    if (appAvailable) {
      if(!loadDefaultApp) {
        this.getSubApp(firstRoleMatch);
      } else {
        this.getApp();
      }
    } else {
      this.ohnService.showAlert('Not authorized', NOT_AUTHORIZED_MESSAGE);
    }
  }

  getSubApp(role: string) {
    this.ohnApi.getElement('app_' + this.ohnService.appRoleModifier(role, true), 2).subscribe(application => {
      this.app = application;
      if (this.app.config.disclaimerEnabled && !this.me.onboarded_at) {
        this.loadDisclaimer();
      }
      this.ohnHealthApiService.initHealthKit(this.app, this.me);
      this.loadPageComponent(this.getHomePage());
    });
  }

  getApp() {
    this.ohnApi.getApp().subscribe(application => {
      this.app = application;
      if (this.app.config.manuallyFillProfile && !this.me.onboarded_at) {
        this.loadProfile();
      }
      if (this.app.config.disclaimerEnabled && !this.me.onboarded_at) {
        this.loadDisclaimer();
      }
      if (this.app.config.replacementsPipe) {
        let replacements : string = localStorage.getItem('ohnReplacements');
        if (replacements) {
          let repUpd : any = JSON.parse(replacements);
          repUpd.settings.pipe = this.app.config.replacementsPipe;
          localStorage.setItem('ohnReplacements', JSON.stringify(repUpd));
        } else {
          localStorage.setItem('ohnReplacements', JSON.stringify({settings: {pipe : this.app.config.replacementsPipe}, data : {}}))
        }
        this.updateLocalProfileReplacements();
      }
      this.ohnHealthApiService.initHealthKit(this.app, this.me);
      this.loadPageComponent(this.getHomePage())
    });
  }

  getHomePage() {
    return _.find(this.app.elements, (e)=>{return e.element_slug == this.app.home_page})
  }

  loadPageComponent(element: OHNElement) {
     let navigationExtras: NavigationExtras = {
      state: {
        app : this.app
      },
      replaceUrl: true
    };
    //this.ohnService.stopLoading().then(()=>{
      this.router.navigate(['secured', element.controller, element.element_slug], navigationExtras);
    //});
  }

  saveTimezone(){
    this.me.timezone = new Date().getTimezoneOffset();
    this.ohnApi.patchUser(this.me).subscribe();
  }

  async loadDisclaimer() {
    const contentPage = await this.modalController.create({
      component: DisclaimerComponent,
      componentProps: {
        text : this.app.config.disclaimerContent
      },
      backdropDismiss: false
    });

    contentPage.onDidDismiss().then((data) => {
      if (data.data.accepted) {
        this.me.onboarded_at = new Date();
        this.ohnApi.patchUser(this.me).subscribe();
      } else {
        this.authenticationService.logout();
      }
    })

    return await contentPage.present();
  }

  async loadProfile() {
    const contentPage = await this.modalController.create({
      component: BranchedContentRendererComponent,
      componentProps: {
        parentElementSlug : 'patient_profile',
        user : this.me,
        splitSinglePageByElements : true,
        lockCloseButton : true
      },
      backdropDismiss: false
    });

    contentPage.onDidDismiss().then((data) => {
      if (data.data.data) {
        this.me.profiles.patient_profile = data.data.data;
        this.updateLocalProfileReplacements();
        this.me.onboarded_at = new Date();
        this.ohnApi.patchUser(this.me).subscribe();
      }
      if (this.app.config.onboardingSlug) {
        this.loadOnboarding();
      }
    })

    return await contentPage.present();
  }

  updateLocalProfileReplacements(){
    if (localStorage.getItem('ohnReplacements') && this.me.profiles.patient_profile && this.me.profiles.patient_profile.value) {
      let replacements : any = JSON.parse(localStorage.getItem('ohnReplacements'));
      replacements.settings.pipe.forEach((p)=>{
        if (this.me.profiles.patient_profile.value[p.withProfileElementSlug]) {
          replacements.data[p.replace] = this.me.profiles.patient_profile.value[p.withProfileElementSlug]
        }
      })
      localStorage.setItem('ohnReplacements', JSON.stringify(replacements));
    }
  }

  async loadOnboarding() {
    const contentPage = await this.modalController.create({
      component: BranchedContentRendererComponent,
      componentProps: {
        parentElementSlug : this.app.config.onboardingSlug,
        user : this.me,
        lockCloseButton : true,
        isOnboarding : true
      },
      backdropDismiss: false
    });

    contentPage.onDidDismiss().then((data) => {
    })

    return await contentPage.present();
  }
}